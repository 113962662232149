import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { instance as axiosInstance } from 'config/axios';
import { API_GRAPH_URL } from 'state/constants/api';
import { queryClient } from 'config/reactquery';
import Input from '../BaseComponents/Input';
import Button from '../BaseComponents/Button';
import Container from '../BaseComponents/Container';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

interface EditLinkModalProps {
  graphId: string;
  text: string;
  url: string;
  modalAnchorElement?: Element;
  onClose: Function;
}

function EditLinkModal(props: EditLinkModalProps) {
  const { graphId, text, url, modalAnchorElement, onClose } = props;
  const { t } = useTranslation();
  const [linkText, setLinkText] = useState(text);
  const [href, setHref] = useState(url);

  // TODO: use the mutateNodeData from the common hooks once
  // we are done migrating commitments/interlocks to tanstack-query
  const tenantID = useSelector((state: any) => state.auth.tenantID);

  const mutateNodeData = function mutateNodeData(node: any): void {
    queryClient.setQueryData([node.id], oldInstance => {
      const oldData = get(oldInstance, 'data', {});
      return { ...oldData, ...node };
    });
  };

  const mutation = useMutation({
    mutationFn: payload => axiosInstance.post(`${API_GRAPH_URL}/${tenantID}/node`, payload),
    onSuccess: data => {
      // TODO: clean submitted fields from the dirty fields object in RQForm?
      mutateNodeData(data.data.result.node);
    },
    onError: err => {
      console.log('error: ', err);
    },
  });

  const save = e => {
    if (!!href) {
      // @ts-ignore
      mutation.mutate({
        id: graphId,
        body: {
          display_name: linkText,
          url: href,
        },
      });
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      onClose();
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { code } = event;

    if (code === 'Enter') {
      save(event);
    }

    if (code === 'Escape') {
      onClose();
    }
  };

  return (
    <Popover open anchorEl={modalAnchorElement}>
      <Container alignItems="stretch" spacing={2} sx={{ minWidth: 288 }}>
        <Typography variant="subtitle2">{t('relations.addMenu.createLink')}</Typography>
        <Input
          placeholder={t('general.richText.linkText')}
          size="large"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => setLinkText(event.target.value)}
          value={linkText}
          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
          sx={{ mb: 2 }}
        />
        <Input
          placeholder={t('general.richText.url')}
          size="large"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
          value={href}
          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
          sx={{ mb: 2 }}
          autoFocus
        />
        <Container direction="row" justifyContent="flex-end" sx={{ pt: 1 }}>
          <Button onClick={() => onClose()}>{t('general.cancel')}</Button>
          <Button color="secondary" variant="contained" disabled={!href} onClick={save}>
            {t('general.save')}
          </Button>
        </Container>
      </Container>
    </Popover>
  );
}

export default EditLinkModal;
