import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TgIcon from 'Components/Library/TgIcon';

const styles = theme => {
  const classes = {
    animationContainer: {
      padding: 12,
      height: 88,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    capsules: {
      transform: 'scaleY(0)',
      opacity: 0,
      transition: 'all .25s ease-out',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    capsule: {
      width: 8,
      height: 54,
      borderRadius: 4,
      marginRight: 2,
      backgroundColor: theme.palette.secondary.main,
      animation: '$wave 2s ease-in-out infinite',
    },

    '@keyframes wave': {
      '0%': {
        transform: 'translateY(-12px)',
        opacity: 0.6,
      },
      '50%': {
        transform: 'translateY(12px)',
        opacity: 0.2,
      },
      '100%': {
        transform: 'translateY(-12px)',
        opacity: 0.6,
      },
    },
    mounted: {
      transform: 'scaleY(1)',
      opacity: 1,
    },
    loadingBox: {
      border: '1px solid #D9D9D9',
      borderRadius: 16,
    },
  };

  for (let i = 0; i < 8; i++) {
    classes[`capsule${i + 1}`] = {
      animationDelay: `${-2 + i * 0.25}s`,
    };
  }
  return classes;
};

function AiLoadingIndicator({ classes, title }) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 5);
  }, []);
  return (
    <Stack
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        ml: 0.5,
      }}
    >
      <Box component="span" className={classes.loadingBox}>
        <Chip
          icon={<TgIcon sx={{ color: theme => theme.palette.text.secondary }} />}
          label={title}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'white',
            marginTop: '18px',
            marginLeft: '15px',
            marginBottom: '42px',
          }}
        />
        <div className={classes.animationContainer}>
          <div className={clsx(classes.capsules, mounted && classes.mounted)}>
            {[...Array(34).keys()].map((k, index) => (
              <div
                key={`ai-loading-anim-capsule-${index}`}
                className={clsx(classes.capsule, classes[`capsule${(k % 8) + 1}`])}
              />
            ))}
          </div>
        </div>
      </Box>
    </Stack>
  );
}

AiLoadingIndicator.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
};

export default withStyles(styles)(AiLoadingIndicator);
